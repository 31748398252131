
:root {
  --color-theme: rgb(10, 163, 51);
  --color-theme-translucent: rgba(10,163,51, 0.8); 
  --color-theme-light: #0ff83a; 
  --color-highlight1: #FEFEFE; 
  --color-highlight2: rgba(191, 246, 191, 0.933); 
  --color-highlight3: #3ac8f7; 
  --color-highlight4: #e45dc9; 
  --color-highlight5: #f6ba3a; 
  --color-dark: #333333;
  --color-gradient: 
    radial-gradient(
      circle at top left,
      var(--color-theme), 
      transparent 900px
    ),
    radial-gradient(
      at bottom left,
      var(--color-highlight1), 
      transparent 600px
    ),
    radial-gradient(
      circle at top right,
      var(--color-theme-light), 
      transparent 600px
    ),
    radial-gradient(
      at bottom right,
      var(--color-highlight1), 
      transparent 800px
    );

}

.root {
  background-color: grey;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


.App {
  max-width: 1200px;
  align-self: center;
  margin: auto;
  text-align: left;
}

img {
  filter: grayscale(100%);
}

/* -------------------------------------------------------------------*/
/*                  Buttons & Inputs                                         */
/* -------------------------------------------------------------------*/

.btn-custom {
  border-width: 1px;
  border-color: var(--color-theme);
  border-style: solid;
  padding: 3px;
  color: var(--color-theme);
  /* font-style: bold; */
  width: 100%;
  display: block;
  text-align: center;
}

.btn-custom:hover {
  background-color: var(--color-highlight4);
  color: white;
  border-color: var(--color-highlight4);
  /* font-style: bold; */
  text-decoration: none;
}

select, .resources-search {
  border-width: 1px;
  border-style:inset;
  padding: 3px;
  color: var(--color-theme);
  /* font-style: bold; */
  background-color: white;
  margin: 30px;
}


/* -------------------------------------------------------------------*/
/*                  Title Bar & Navigation                           */
/* -------------------------------------------------------------------*/

a.navlink {
  color: var(--color-theme);
  text-decoration: none;
}

a.navlink:hover {
  text-decoration: none;
  color: var(--color-highlight4)
}

.title-bar {
  position: fixed !important;
  z-index: 100;;
  width: 100%;
  max-width: 1200px;
  background-color: white;
  color: var(--color-theme);
  height: 2.5rem;
  border-bottom: var(--color-theme);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.title-bar-margin {
  height: 2.5rem; 
}

.App-logo-header {
  height: 20px;
  width: 20px;
  margin: 10px 1px;
  filter: none;
}

.logo {
  display: inline;
  float: left;
  width: 150px;
  filter: none;
}

.logo > p {
  color:var(--color-theme);
  display: inline;
  
}

.title-bar-spacer2 {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 10000;
}

.menu {
  display: inline;
  float: right;
}

nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
}

nav label {
  font-size: 2em;
  cursor: pointer;
  display: none;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 5px;
  display: inline;
  float: right;
}

nav ul li {
  display: inline;
  margin: 10px 10px;
}

nav > ul > li > a {
  color: var(--color-theme);
  text-decoration: none;
}

nav > ul > li > a:hover {
  color: var(--color-highlight4);
  text-decoration: none;
}



nav.breadcrumbs {
  display: flow-root;
  color: var(--color-theme);
  margin-bottom: 30px;
}

nav.breadcrumbs > ul {
  float: left; 
}

nav.breadcrumbs > ul > li {
  padding: 0;
  margin: 0;
}

.App-link {
  color: #61dafb;
}

/* -------------------------------------------------------------------*/
/*                "Homemade" hamburger Navigation                      */
/* -------------------------------------------------------------------*/

.hamburger {
  display: none;
}

.mobile-nav {
  /* display: block; */
  position:absolute;
  font-size: xxlarge;
  background-color: white;
  color:white;
  font-size: x-large;
  list-style-type: none;
  text-align: center;
  font-variant: small-caps;
  height: 100vh; 
  width: 100vw;
  left: -100vw;
  opacity: 0;
}


.mobile-nav a {
  /* display: none;
  width: 0; */
}

.mobile-nav.open a {
  color: var(--color-theme);
  align-items: center;
  /* display: none; */

}

.mobile-nav li:hover a {
  color: var(--color-highlight4);
  text-decoration: none;
}


@media screen and (max-width: 700px) {

  .hamburger { 
    display: flow; 
    padding-top: 10px; 
    margin-right: 10px;
    z-index: 10;
    /* float: right; */
  }

  .full-screen-nav {
    display: none;
  }

  .mobile-nav.open {
    left: 0;
    opacity: 1;
    /* position: absolute; */
    transition: all .25s ease-out;
    
  }

  


}

#nav-icon1 {
  width: 20px;
  height: 22px;
  /* position: relative; */
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  /* margin: 10px  0 0 0; */
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--color-theme);
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 6px;
}

#nav-icon1 span:nth-child(3) {
  top: 12px;
}

#nav-icon1.open span:nth-child(1) {
  top: 6px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -20px;
}

#nav-icon1.open span:nth-child(3) {
  top: 6px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* -------------------------------------------------------------------*/
/*                  New style Navigation                           */
/* -------------------------------------------------------------------*/
/* https://codemafias.com/How-to-create-working-responsive-navbar-with-dropdown-menu-using-React */


/* .navbar {
  position: relative;
  background: rgba(0, 212, 255, 1);
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  box-shadow: 1px 2px 5px lightgray;
} */



.nav-side-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 0 10px;
  width: 70vw;
  justify-content: end;
  margin-right: 35px;
}

.nav-items {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 8px 20px;
  transition: all 0.3s ease-out;
}

.nav-links:hover {
  background-color: #000;
  border-radius: 5px;
}

.fa-bars {
  color: var(--color-theme-light)
}

.nav-links-button {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  
  .nav-side-menu {
      display: flex;
      flex-direction: column;
      width: 250px;
      height: calc(100vh - 85px);
      position: absolute;
      margin-top: 0;
      top: 85px;
      border: 2px solid #ececee;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      justify-content: flex-start;
  }

  .nav-side-menu.start {
      background: #fff;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
  }

  .nav-links {
      text-align: center;
      padding: 1.5rem;
      width: 100%;
      color: #000;
      display: table;
      transition: 0s;
  }

  .nav-links:hover {
      color: #fff;
  }
/* 
  .logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
  } */

  .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
  }

  .fa-times {
      color: #fff;
      font-size: 2rem;
  }

  .nav-links-button {
      display: block;
      padding: 12px 30px;
      margin: 25px auto;
      border-radius: 10px;
      background: #000;
      text-decoration: none;
      color: #fff;
      font-size: 1.3rem;
  }

  /* button {
      display: none;
  } */

  .nav-items {
      height: auto;
  }
}

/* -------------------------------------------------------------------*/
/*                  Header                                         */
/* -------------------------------------------------------------------*/

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
}


.App-header {
  background-color: var(--color-theme);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.page-header {
  margin: 0;
  height: 50vh;
  width: 100%;
  font-weight: bold;
  font-size: 3rem;
  color: white;
  text-align: left;
  padding: 10px;
  background-repeat: no-repeat;
  background-image: linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url("https://images.unsplash.com/photo-1598257006626-48b0c252070d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");
  background-size: cover;
  filter: grayscale(100%);
};



/* -------------------------------------------------------------------*/
/*                  Sections                                         */
/* -------------------------------------------------------------------*/

section {
  width: 100%;
  margin-top: 30px;
  padding: 50px 40px 20px 40px; 
}

.section {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
  padding: 20px 40px 20px 40px;  
}

#section-industries{
  background-image: linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(240, 263, 251, 0.2)), 
    url("https://www.shutterstock.com/image-vector/modern-city-skyline-vector-260nw-1242271444.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
 }

 #section-industries > h4 {
  margin-bottom: 30px;
 }

#industry-cards {
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 600px) {
  #industry-cards {
    grid-template-columns: 1fr;
  }
}


#section-delivery{
  color: black;
  background-image: linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(240, 263, 251, 0.2)), 
    url("https://img.freepik.com/premium-vector/silhouette-people-crowd-white-background_566661-7708.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* background-color: #999999; */
  background-position-y: top;
  /* background-blend-mode: darken; */
  
 }

 #section-delivery > h4 {
  margin-bottom: 30px;
 }

#section-highlights {
  background-image: linear-gradient(to top, rgba(245, 246, 252, 0.3), rgba(0, 163, 51, 0.7)), url("https://images.unsplash.com/photo-1533460004989-cef01064af7e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Z3Jhc3N8ZW58MHx8MHx8&w=1000&q=80");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding-top: 30px;
  
}

#section-departments {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 10%;
  padding-right: 10%;
}

.section-panel {
  display: block;
  width: 50%;
  padding: 20px;
  background-color: white;
  margin: 60px 0px -20px 30px;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
}

.section-panel:first-child {
  margin: -20px -60px 20px 10px;
  
}

@media only screen and (max-width: 700px) {
  .section-panel {
    min-width: 100%;
    margin: 10px;
    display: inline;
  }
}



/* -------------------------------------------------------------------*/
/*                  Footer                                           */
/* -------------------------------------------------------------------*/

.footer {
  background-color: var(--color-dark);
  color: white;
  border-top: var(--color-theme);
  border-top-width: 3px;
  border-top-style: solid;
  padding-top: 10px;
  padding: 10px 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.footer-intro {
  padding: 10px;
}

.footer-intro > h4 {
  display: inline;
  margin-left: 10px;
  margin-top: 10px;
  color:var(--color-theme)
}

.footer-intro > img {
  margin-top: 0px;
}

.footer-columns {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
  padding: 10px;

}

.footer-navigation-section {
  display: flex;
  min-width: 250px;
   
}

.footer-navigation-section > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.footer-navigation-section > ul > li:first-child {
  
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.footer-navigation-section > ul > li > a {
  color: white;
}




/* -------------------------------------------------------------------*/
/*                  Highlights                                      */
/* -------------------------------------------------------------------*/

.highlight-reel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: stretch;
  padding: 10px;
}

@media only screen and (max-width: 500px) {
  .highlight-reel {
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 1000px) {
  .highlight-reel {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.highlight {
  min-width: 180px;
  justify-content: center;
  text-align: center;
  color: white;
  margin: 0px;
}


/* -------------------------------------------------------------------*/
/*                  Cards    etc                                      */
/* -------------------------------------------------------------------*/


.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 30px;
  align-items: stretch;
  /* padding: 20px; */
}

@media only screen and (min-width: 1000px) {
  .cards-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.card {
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  flex-direction: column; 
}

.card.card-title {
  display: flex;
  background: var(--color-gradient);
  color: white;
  padding: 20px;
  font-size: 3rem;
  min-height: 100%;
  /* font-style: bold; */
  /* font: verdana; */
  font-size: x-large;
  grid-column: 1 / 3;
}

@media (max-width: 800px) {
  .card.card-title {
    grid-column: 1 / 2;
  }
}

.card-image {
  display: flex;
  width: 100%;
  height: 200px;
  object-fit: cover;
  
  min-height: 50px;
}

.card-text {
  padding: 20px;
  display: flex; 
  flex-direction: column; 
  flex-grow: 1;

}

.card-type {
  /* font-variant: small-caps; */
  color: var(--color-theme);
  /* font-size: small; */
}

.card-text > p {
    flex-grow: 1; 
}

/* .minicards-container {
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 40px;
} */

.minicard {
  text-align: left;
  color: white;
  background-color: var(--color-theme-translucent);
  
}

.minicard > .btn-custom {
  padding: 10px;
  color: white;
  background-color: transparent;
  border-color: white;
  height: 100%;
}

.minicard > .btn-custom:hover {
  background-color: var(--color-highlight4);  
}

.minicard > .btn-custom > h3 {
  text-align: center;
  margin: 0;
  
}

.minicard > .btn-custom > p {
  text-align: left;
  margin: 0;
}

.picture-card {
  display: grid;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #ccc;
  /* box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3); */
  text-decoration: none;
}

.picture-card > * {
  grid-area: 1/1;
  transition: .4s;
}

.picture-card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  min-height: 100%;
}



.picture-card-title {
  display: grid;
  align-items: end;
  font-family: sans-serif;
  color: white;
  
  padding: .75rem;
  background: var(--c,#0009);
  clip-path: inset(0 var(--_i,100%) 0 0);
  /* -webkit-transform: translate3d(0,0,0); */
  /* -webkit-mask:
    linear-gradient(#000 0 0),
    linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  -webkit-mask-clip: text, padding-box; */
}

.picture-card:hover > .picture-card-title > h3 {
  color: white;
  text-decoration: none;
  font-weight: bold;
  /* -webkit-transform: translate3d(0,0,0); */
  
}

.picture-card:hover > .picture-card-title > a:hover {
  text-decoration: none;
  font-weight: bold;
  /* -webkit-transform: translate3d(0,0,0); */
  
}


.picture-card:hover .picture-card-title {
  --_i: 0%;
  text-decoration: none;
  /* -webkit-transform: translate3d(0,0,0); */
  

}
.picture-card:hover .picture-card-image {
  /* -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transform: translate3d(0, 0, 0);
  transform: scale(1.2); */
  transform: scale(1);
}



.picture-slice-container {
  width: 100%;
  /* justify-content: top; */
  padding: 30px;
}

.picture-slice {
  width: 100%;
  display: block;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width:600px) {
  .picture-slice-container {
    padding: 0;
  }
  
}

.picture-slice-image-frame {
  width: 20%;
  height: 100%;
  display: inline-block;
}

.picture-slice-image {
  width: 20%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
  display: inline-block;
  margin-top: 0px;
  vertical-align:top; 
}



.picture-slice-text {
  display: inline-block;
  /* display: inline-block; */
  width: 80%;
  padding: 20px;
  /* display: flex;  */
  flex-direction: column; 
  flex-grow: 1;
}

/* .picture-slice-title > {
  color: var(--color-theme);
} */

/* .picture-slice-description> {
  color: var(--color-theme);
} */

/* .picture-slice-content > {
  color: black;
} */



.simple-list {

  text-align: left;
  padding: 1rem 3rem;
}

.list-link {
  color: var(--color-theme);
  font-size: large;
}

.list-link:hover {
  color: var(--color-highlight4);
  text-decoration: none;
}


/* -------------------------------------------------------------------*/
/*                  Contact form                                      */
/* -------------------------------------------------------------------*/


#contact-form {
  padding: 10px;

}

#email-contact {
  max-width: 80%;
  display: inline;
  background-color: rgba(255, 255, 255, 0.136);
  color: black;
  border-color: grey;
 
}

#email-button {
  width: 18%;
  display: inline;
  float: right;
  background-color: var(--color-theme);
  color: white;
  border-color: grey;
  margin-left: 2%;
  margin-bottom: 2%;
}

#email-button:hover {
 background-color: var(--color-highlight4);
}

#email-message {
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0.136);
  color: black;
  border-color: grey;
  
}


/* -------------------------------------------------------------------*/
/*                  Resources page                                   */
/* -------------------------------------------------------------------*/


#resource-selector-form {
  display: grid;
  grid-template-columns: 2fr 1fr;  
}

@media (max-width: 600px) {
  #resource-selector-form {
    display: grid;
    grid-template-columns: 1fr;  
  }
}

.resources-search {
  width: 100%;
  margin: 10px;
  height: 30px;
}

#filter-by-type {
  width: 100%;
  margin: 10px;
  height: 30px;
}

.resources-table {
  width: 100%;
  margin: 10px;
}

.resources-table tr td{
  border-top-color: lightgray;
  border-top-width: 1px;
  border-top-style: solid;
}


.resources-table tr td,th {
  vertical-align: top;
  padding: 2px 3px 10px 3px;
}

.resources-table .nugget-thumbnail {
  height: 50px;
  width: 70px;
  object-fit: cover;
}

/* -------------------------------------------------------------------*/
/*                  Nugget Page                                       */
/* -------------------------------------------------------------------*/


.nugget-header-image {
  width: 100%;
  object-fit: cover;
  height: 50vh;
  width: 100%;
        
}

.nugget-section {
  padding: 20px;
}

.nugget-title {
  margin-bottom: 5px;
 
}

.nugget-sub-title {
  color: var(--color-theme);
  margin-bottom: 40px;

}

